//==============================================================================
// Conditional Customer Status Container
//
// Light-weight module that shows child modules only if the currently logged in
// user has a matching status to a selected config customer status. Otherwise, nothing is added to the DOM.
//==============================================================================
import * as React from 'react';

import { convertCustomerAttributes } from '../../utilities/data-attribute-parser';
import { CustomerStatus } from '../../settings/app.settings';

import { IOnHoldContainerData } from './on-hold-container.data';
import { IOnHoldContainerProps } from './on-hold-container.props.autogenerated';


//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ConditionalContainer component
 * @extends {React.PureComponent<IOnHoldContainerProps<IOnHoldContainerData>>}
 */
//==============================================================================
class ConditionalContainer extends React.PureComponent<IOnHoldContainerProps<IOnHoldContainerData>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { slots, data, config } = this.props;

        // Ensure there are child slots. If not, abort.
        const hasSlots = slots && slots.childModules && slots.childModules.length;
        if (!hasSlots) {
            return null;
        }

        // Get customer info. If it hasn't been loaded yet, abort.
        const customerData = data.customerInformation.result;
        if (!customerData) {
            return null;
        }

        if (config.conditionType === 'onHold') {

            // If the customer doesn't have a credit hold, abort.
            if (!customerData.Blocked) {
                return null;
            }

        } else if (config.conditionType === 'notOnHold') {

            // If the customer has a credit hold, abort.
            if (customerData.Blocked) {
                return null;
            }

        } else {

            const customerAttributes = convertCustomerAttributes(customerData.Attributes || []);
            const onBoardingStatus = customerAttributes.CusOnboardStatus;
            const customerStatusIds = this.props.context.app.config.customerStatus as CustomerStatus;

            // If the customer doesn't have new customer status abort
            if (config.conditionType === 'newCust' && onBoardingStatus !== customerStatusIds.customerFirstTimeBuyerId) {
                return null;
            }

            // If the customer is not pending credit check abort
            if (config.conditionType === 'pendingCreditCheck' && onBoardingStatus !== customerStatusIds.customerOnHoldId) {
                return null;
            }

        }

        // Customer status condition met, render the child modules.
        return this._renderSlotItems(slots.childModules);
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderSlotItems(items: React.ReactNode[]): JSX.Element {
        return (
            <React.Fragment>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }
}

export default ConditionalContainer;
